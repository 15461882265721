<template>
  <div class="page">
    <columnist-item
      class="item-box"
      v-for="(item, idx) in columnistList"
      :key="idx"
      :info="item"
      @click.native="onDetails(item.albumId)"
    >
    </columnist-item>
  </div>
</template>

<script>
import { getAlbumList } from "@/api/album";
import ColumnistItem from "../home/AlbumItem.vue";
import to from "@/utils/to";
export default {
  components: {
    ColumnistItem,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      loading: false,
      columnistList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.$showLoading("加载中...");
      let [err, res] = await to(getAlbumList());
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }
      this.columnistList = res;
    },
    onDetails(id) {
      this.$router.push({
        name: "Album",
        query: { id },
      });
    },
  },
};
</script>

<style scoped>
.page {
  padding: 16px 10px 16px 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-box {
  margin-bottom: 8px;
}
</style>